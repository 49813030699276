<template>
  <div >
    <div class="min-w-0 px-4 select-none single-mappings">
      <div v-for="mesh in mappings" @mouseover="highlightMesh(mesh.name)" @mouseleave="highlightMesh(null)">
        <div class="flex items-center py-1 my-0 border-b border-gray-700">
          <div class="w-2/3 mx-1">
            <label
              class="inline-flex items-center flex-1 w-full cursor-pointer"
            >
              <input
                v-model="selectedMeshes"
                type="checkbox"
                class="w-3 h-3 bg-gray-400 rounded form-checkbox"
                :value="mesh.name"
              />
              <span class="ml-2 text-xs text-gray-300 truncate no-select">{{
                mesh.name
              }}</span>
            </label>
          </div>
          <template v-if="mesh.uvs.length > 1">
            <select
              :name="'uvselect-' + mesh.name"
              :disabled="!isMeshSelected(mesh.name)"
              :value="selectUvForMesh(mesh)"
              class="w-1/3 px-4 py-1 pr-2 text-xs text-gray-700 truncate bg-gray-300 border border-gray-700 rounded appearance-none form-select focus:outline-none focus:bg-white focus:border-gray-500"
              :class="{
                'opacity-50 cursor-not-allowed': !isMeshSelected(mesh.name)
              }"
              @change.prevent="
                updateSelectUvForMesh(mesh.name, $event.target.value)
              "
            >
              <option v-for="uv in mesh.uvs" class="truncate">
                {{ uv }}
              </option>
            </select>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MappingSelect',

  props: {
    mappings: {
      type: Array,
      required: true
    },

    target: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedMeshes: [],
      selectedUvs: []
    }
  },

  computed: {
    hasMappingGroups() {
      return this.mappingGroups.length > 0
    },

    mappingGroups() {
      return []
    },

    selectedWithUv() {
      let ret = []

      this.selectedMeshes.forEach((meshname) => {
        let key = meshname
        let uv = this.selectUvForMeshname(meshname)

        if (uv !== undefined) {
          key = key + ':' + uv
        }

        ret.push(key)
      })

      return ret
    }
  },

  watch: {
    selectedWithUv(value) {
      this.$emit('select-mapping', value)
    }
  },

  mounted() {
    if (this.target.length < 1) return

    this.target.forEach((row) => {
      let [name, uv] = row.split(':')

      this.selectedMeshes.push(name)

      if (uv !== undefined) {
        let row = { mesh: name, uv: uv }
        this.selectedUvs.push(row)
      }
    })
  },

  methods: {
    highlightMesh(meshname) {
      this.$emit('highlight-mesh', meshname)
    },
    isMeshSelected(meshname) {
      return this.selectedMeshes.includes(meshname)
    },
    onEditGroup(group) {
      this.$emit('edit-group', group)
    },

    onDeleteGroup(group) {
      this.$emit('delete-group', group)
    },

    selectUvForMeshname(meshname) {
      let mesh = this.mappings.find((row) => {
        return row.name === meshname
      })

      return this.selectUvForMesh(mesh)
    },

    selectUvForMesh(mesh) {
      let row = this.selectedUvs.find((row) => {
        return row.mesh === mesh.name
      })

      if (row !== undefined) return row.uv
      return mesh.uvs[0]
    },

    updateSelectUvForMesh(meshname, value) {
      let row = this.selectedUvs.find((row) => {
        return row.mesh === meshname
      })

      if (row === undefined) {
        let newrow = { mesh: meshname, uv: value }
        this.selectedUvs.push(newrow)
      } else {
        row.uv = value
      }
    }
  }
}
</script>
